h1.loadingtext {
  // text-align: center;
  // text-transform: uppercase;
  // font-family: 'Nunito', sans-serif;
  font-size: .3rem;
  // color: transparent;
  letter-spacing: 0.01em;
  text-align: center;
  span {
    animation: load-text 1s infinite cubic-bezier(0.1, 0.15, 0.9, 1);
    display: inline-block;
  }
}


@keyframes load-text {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}


.loading-bar__fill{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  video{
    width: 1.2rem;
  }
  img{
    width: 1.2rem;
  }
  .num{
    font-size: .3rem;
    font-weight: 500;
  }
  .text{
    font-size: .18rem;
    font-weight: normal;
    text-align: center;
    margin-top: .72rem;
  }
  .loading_canvas{
    width: 1.5rem;
  }
}




@media screen and (max-width: 720px) {
  .loading-bar__fill{
    .num{
      font-size: .16rem;
    }
    .text{
      font-size: .14rem;
      font-weight: normal;
      text-align: center;
      margin-top: 1.14rem;
    }
    .loading_canvas{
      width: .8rem;
    }
  }
}
