@import "../fonts/style";

// Theme
$title-color: rgb(255, 255, 255);
$subtitle-color: rgb(255, 255, 255);
$primary-text-color: red;
$secondary-text-color: yellow;

// Font
$font: "Helvetica Neue",
Helvetica,
"PingFang SC",
"Hiragino Sans GB",
"Microsoft YaHei",
Arial,
sans-serif;
$font-size: 32px;

$base: 1920 / 100vw;
$mobileBase: 750 / 100vw;
