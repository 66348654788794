@import "../../../style/index.scss";

.mobile-header {
  width: 100vw;
  overflow-x: hidden;
  height: 1rem;
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  background: url(../../../assets/images/header_bg.png) no-repeat center;
  background-size: 100% 100%;
  // background: rgba(0, 0, 0, 0.04);
  // -webkit-backdrop-filter: blur(10 / $mobileBase);
	// backdrop-filter: blur(10 / $mobileBase);
  // border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  &-container {
    padding: .16rem;
    display: flex;
    align-items: center;
    height: 100%;

    &-logo {
      // height: .6rem;
      

      a {
        display: flex;
        align-items: center;

        img {
          // width: 2rem;
          height: .65rem;
        }
      }
    }

    &-spec {
      flex: 1;
    }
    .right{
      .header-nav-item-title{
        font-family: 'NexaBlack';
        font-size: .32rem;
        line-height: 1;
      }
    }
    &-navBtn {
      display: flex;
      align-items: center;
    }
  }

  .iconUl {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .icon ul li {
    width: 34 / $mobileBase;
    height: 4 / $mobileBase;
    background: #fff;
    transition: all 0.3s;

    &:nth-child(2) {
      margin-bottom: 0;
      margin: 6/$mobileBase auto;
    }
  }

  .icon ul:hover {
    cursor: pointer;
  }

  .iconUl.active li:nth-child(2) {
    opacity: 0;
  }

  .iconUl.active li:nth-child(1) {
    transform: translateY(10/$mobileBase) rotate(45deg)
  }

  .iconUl.active li:nth-child(3) {
    transform: translateY(-10/$mobileBase) rotate(-45deg);
  }
}

.phoneNav .navList {
  // margin-top: .8rem;
  padding: 48/$mobileBase 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: #0D0D0D;
  border-left: none;
  border-right: none;
}

.phoneNav .pad3 {
  padding: 3vw 0;
}

.phoneNav .navList a {
  width: 100%;
  color: $title-color;
  font-size: .3rem;
  font-weight: 500;
  letter-spacing: .03rem;
  padding: .3rem 0;
  font-family: "SFB";
}

.phoneNav .navList a img {
  width: .24rem;
}

.phoneNav .navList .active a,
.phoneNav .navList .active,
.navList a:hover {
  text-decoration: none;
}

.phoneNav .navList .item {
  border-bottom: .01rem solid #D8D8D8;
}

.nav-model {
  position: fixed;
  left: 0;
  top: 100%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
  transition: all 0.3s linear;
  padding-top: 120 / $mobileBase;;
}

.nav-model.active {
  top: 0;
}

.nav-model .model-content {
  width: 100%;
  height: 100%;
}

.phoneNav {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.spec {
  flex: 1;
}

.navlist-item {
  width: 100%;
  height: 1.2rem;
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-weight: bold;
  justify-content: center;
  font-family: "SFB";
  color: #CCCCCC;
  &.active{
    color: #29FA51;
    span{
      display: block;
      position: relative;
      &::after,&::before{
        content: '';
        width: 16 / $mobileBase;
        height: 24 / $mobileBase;
        position: absolute;
        top: 50%;
        // background: url(../../../assets/images/arrow.png) no-repeat center;
        background-size: cover;
        transform: translateY(-50%);
      }
      &::before{
        left: -24 / $mobileBase;
        transform: translateY(-50%) rotateY(-180deg);
      }
      &::after{
        right: -24 / $mobileBase;
      }
    }
  }
}


.menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
}
.line {
  fill: none;
  stroke: white;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}

.mobileLitepaperModel{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  // z-index: 999999;
  opacity: 0;
  // z-index: -1;
  .content{
    width: 6.7rem;
    height: 3.8rem;
    border-radius: 0.2rem;
    background: #fff;
    padding: 0.4rem 0.5rem;
    .title{
      font-family: 'MERegular';
      font-size: .32rem;
      line-height: 1;
      color: #000;
      margin: 0;
      margin-bottom: .4rem;
      text-align: center;
    }
    .input_item{
      margin-bottom: .36rem;
      input{
        border: none;
        background: #F7F8FC;
        width: 100%;
        height: .8rem;
        font-size: .24rem;
        line-height: 1;
        padding: .24rem .3rem;
        margin: auto;
        display: block;
        &::placeholder{
          color: rgba(0, 0, 0, 0.2);
          font-size: .24rem;
          line-height: 1;
        }
      }
    }
    .btnGroup{
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        width: 2.5rem;
        height: .7rem;
        font-size: .24rem;
        font-family: 'MERegular';
        margin: 0 .15rem;
        background-color: transparent;
        border: none;
        color: #000;
        &.btn1{
          background-image: url(../../../assets//images/cancel.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
        &.btn2{
          background-image: url(../../../assets//images/confirm.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
      }
    }
  }
}
