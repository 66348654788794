@import "./style/index.scss";

iframe {
  display: none;
}


html,body{
  background: #232323;
  width: 100%;
  // overflow-x: hidden;
}

.Toastify{
  font-size: .16rem;
  .Toastify__toast-container{
    z-index: 999999;
    top: 55%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    .upComing{
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      font-family: MERegular;
      text-align: center;
      font-size: 28/$mobileBase;
      max-width: 420/$mobileBase;
      border-radius: 4px;
      margin: auto;
      .Toastify__close-button{
        display: none;
      }
    }
  }

  @media (min-width:640px) {
    .Toastify__toast-container{
      z-index: 999999;
      top: 55%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      .upComing{
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        font-family: MERegular;
        text-align: center;
        font-size: 20/$base;
        .Toastify__close-button{
          display: none;
        }
      }
    }
  }
}