.newHome_loading_box{
  position: fixed;
  
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all linear .5s;
  &.hide{
    opacity: 0;
    z-index: -9999;
  }
  &.show{
    z-index: 999999;
    opacity: 1;
  }
}