@import "../../../style/index.scss";

.header {
  width: 100%;
  height: 80 / $base;
  // position: fixed;
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 100 / $base;
  z-index: 10000;

  &-blur {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // background: rgba(0, 0, 0, 0.04);
    // -webkit-backdrop-filter: blur(10 / $base);
	  // backdrop-filter: blur(10 / $base);
    background: url(../../../assets/images/header_bg.png) no-repeat center;
    background-size: 100% 100%;
    z-index: 10001;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10002;
    padding-top: 31 / $base;
    padding-bottom: 27 / $base;
    // border-bottom: .01rem solid #e0e2e44d;
  }

  &-logo {
    width: 156 / $base;
    height: 68 / $base;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &-nav {
    display: flex;
    align-items: center;
    margin-right: -55 / $base;
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 55 / $base;
      cursor: pointer;

      &:nth-last-child(1) {
        margin-right: 0;
      }

      &:first-child {
        margin-right: 77 / $base;
      }

      // &:hover,&.active {
      //   .header-nav-item-title {
      //     color: $primary-text-color;
      //   }

      //   .header-nav-item-doll {
      //     width: 16 / $base;
      //   }
      // }

      &-title {
        font-size: 18 / $base;
        line-height: 22/ $base;
        color: #fff;
        font-family: "MERegular";
        // margin-bottom: 4 / $base;
        img{
          width: 36 / $base;
        }
      }

      &-doll {
        width: 0vw;
        height: 2 / $base;
        transition: all 0.15s;
        border-radius: 1px;
        background-color: $primary-text-color;
      }
    }
  }
}


.LitepaperModel{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  // z-index: 999999;
  opacity: 0;
  // z-index: -1;
  .content{
    width: 7.5rem;
    height: 3.45rem;
    border-radius: .2rem;
    background: #fff;
    padding: .4rem;
    .title{
      font-family: 'MERegular';
      font-size: .32rem;
      line-height: 1;
      color: #000;
      margin: 0;
      margin-bottom: .36rem;
      text-align: center;
    }
    .input_item{
      margin-bottom: .38rem;
      input{
        border: none;
        background: #F7F8FC;
        width: 6.03rem;
        height: .8rem;
        padding: .23rem .3rem;
        margin: auto;
        display: block;
        &::placeholder{
          color: rgba(0, 0, 0, 0.2);
          font-size: .24rem;
          line-height: 1;
        }
      }
    }
    .btnGroup{
      display: flex;
      align-items: center;
      justify-content: center;
      button{
        width: 2.2rem;
        height: .68rem;
        font-size: .2rem;
        font-family: 'MERegular';
        margin: 0 .15rem;
        background-color: transparent;
        border: none;
        &.btn1{
          background-image: url(../../../assets//images/cancel.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
        &.btn2{
          background-image: url(../../../assets//images/confirm.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
      }
    }
  }
}
