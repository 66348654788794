@import "../../../style/index.scss";

.lead{
  height: 100vh;
  padding: 0 100 / $base;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  position: relative;
  .container{
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 100%;
    position: relative;
    .left{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      .lead_logo{
        // height: .73rem;
        margin-top: .6rem;
        img{
          height: 100%;
        }
      }
      .dec{
        font-family: 'Hellix';
        font-weight: normal;
        font-style: normal;
        font-size: .33rem;
        color: #1d1d1d;
        margin-top: 1.2rem;
        margin-bottom: .85rem;
      }
      .list{
        display: flex;
        align-items: stretch;
        position: relative;
        .item{
          width: 2.6rem;
          height: 2.5rem;
          margin: 0 .1rem;
          background-color: #F0DB2A;
          padding: .2rem;
          clip-path: polygon(100% 0, 100% 1.9rem, 1.65rem 100%, 0 100%, 0 0);
          .title{
            font-family: 'MERegular';
            font-size: 20/$base;
            line-height: 2.1;
            color: #000;
          }
          ul{
            li{
              p{
                color: #fff;
                display: inline-block;
                padding: .05rem .15rem;
                background-color: #48420D;
                clip-path: polygon(100% 0, 100% 84%, 92% 100%, 0 100%, 0 0);
              }
            }
          }
          p{
            font-size: .14rem;
            color: #000;
            line-height: 1.2;
            span{
              font-size: .24rem;
              line-height: 1;
              vertical-align: middle;
            }
          }
        }
        &::after{
          content: '';
          width: 1.68rem;
          height: 1.68rem;
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(50%,50%);
          background: url(../../../assets/images/lead_tip.png) no-repeat center;
          background-size: cover;

        }
      }
    }
    .right{
      display: flex;
      justify-content: center;
      align-items: center;
      .pic{
        width: 8.6rem;
        position: relative;
        margin-bottom: -2rem;
        margin-right: -2rem;
        .selectList{
          width: 100%;
          display: grid;
          grid-template-columns: auto auto auto auto;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: -60/$base;
          left: 50%;
          transform: translateX(-50%);
          z-index: 9;
          .item{
            // width: .1rem;
            // height: .8rem;
            box-sizing: border-box;
            // margin: 0 .rem;
            padding: .05rem;
            margin: 0.05rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
              width: .5rem;
            }
            &.active{
              background: url(../../../assets//images/active_bg.webp) no-repeat center;
              background-size: 100% 100%;
            }
          }
        }
        .selectPic{
          position: relative;
          height: 500/$base;
          .item{
            position: absolute;
            bottom: -50/$base;
            left: 150%;
            height: 100%;
            width: auto;
            transition: all linear 0.7s;
            transform: translateX(-50%);
            &.active{
              left: 50%;
            }
          }
        }
        // img{
        //   width: 100%;
        // }
      }
    }
  }
  .bg{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    background: url(../../../assets/images/lead_bg.png) no-repeat bottom center;
    background-size: 100% 100%;
    width: 100%;
  }
  .footer{
    position: absolute;
    bottom: 20/$base;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 999;
    color: rgba(0, 0, 0, 0.5);
    font-size: 20/$base;
  }
}