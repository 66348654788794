@import "../../../style/index.scss";
.mobileHome{
  // padding-top: .56rem;
  color: #fff;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  // padding-bottom: .8rem;
  .mobilebanner{
    padding: 0 .2rem;
    padding-top: .8rem;
    position: relative;
    height: 12.2rem;
    .bg{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: url(https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/mobile-banner.png) no-repeat top center;
      background-size: 100% 100%;
    }
    .mask{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 0;
      z-index: 99;
      opacity: 0;
      transition: all linear .3s;
      pointer-events: none;
      background-color: rgba(0, 0, 0, 0.12);
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .toast{
          margin-bottom: 0;
          background-color: rgba(0, 0, 0, 0.4);
          font-family: MERegular;
          text-align: center;
          font-size: 28/$mobileBase;
          max-width: 420/$mobileBase;
          border-radius: 4px;
          padding: 20/$mobileBase 40/$mobileBase;
          box-sizing: content-box;
          margin: auto;
        }
      }
      &.active{
        opacity: 1;
      }
    }
    .title{
      font-size: .4rem;
      font-family: 'NexaBlack';
      position: relative;
      margin: 0;
      &::before{
        content: '';
        position: absolute;
        top: -0.45rem;
        left: 0;
        width: .90rem;
        height: .30rem;
        background-image: url(../../../assets//images/banner_star.png);
        background-repeat: no-repeat;
        background-size: 80% 80%;
        background-position: center center;
      }
      &::after{
        content: '';
        position: absolute;
        top: -0.4rem;
        right: -4%;
        width: .77rem;
        height: .77rem;
        background-image: url(../../../assets//images/banner_pic.png);
        background-repeat: no-repeat;
        background-size: 80% 80%;
        background-position: center center;
      }
      .spec_text{
        color: #F0DB2A;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          bottom: -0.45rem;
          left: -40%;
          transform: scale(.8);
          width: 1.36rem;
          height: .70rem;
          background-image: url(../../../assets//images/text_after.png);
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        }
      }
    }
    .subtitle{
      font-size: .14rem;
      font-family: 'Hellix';
      font-weight: bold;
      font-style: normal;
      margin-top: .09rem;
      
    }
    .des{
      font-size: .1rem;
      color: rgba(255, 255, 255, 0.6);
      margin-top: .21rem;
      margin-bottom: .38rem;
    }
    .container{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: -360/$base;
      .des{
        // text-shadow: 0 0 0.8em rgba(0, 0, 0, 0.3), 0 0 0.8em rgba(0, 0, 0, 0.3);
        -webkit-text-stroke-width: 8/$base;
        -webkit-text-stroke-color: #A09AAA;
        color:#fff;
        position: relative;
        text-align: center;
        font-size: .38rem;
        max-width: 16rem;
        font-family: POPB;
        span{
          color: #F0DB2A;
        }
      }
      .btn_group{
        display: flex;
        align-items: center;
        // justify-content: center;
        .btn{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.28rem;
          line-height: 1;
          font-family: 'MERegular';
          transition: all linear .3s;
          width: 2.8rem;
          height: .7rem;
          -webkit-clip-path: polygon(100% 0, 100% 64%, 85% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 100% 64%, 85% 100%, 0 100%, 0 0);
          background-color: rgba(0, 0, 0, 0.27);
          -webkit-backdrop-filter: blur(10 / $mobileBase);
          backdrop-filter: blur(10 / $mobileBase);
          outline: none;
          border: none;
          color: #fff;
          .iconBox{
            width: .360rem;
            margin-right: .13rem;
            svg{
              path{
                fill: #fff;
                transition: all linear .3s;
              }
            }
          }
          &:first-child{
            margin-right: .24rem;
            background-color: #F0DB2A;
            color: #000;
            .iconBox{
            
              svg{
                path{
                  fill: rgb(0, 0, 0);
                }
              }
            }
          }
        }
      }
    }
   
    .banner1{
      // margin: 0 -0.2rem;
      margin-top: .6rem;
    }
  }
  .mobileSwiper{
    padding-top: .8rem;
    position: relative;
    .order{
      display: flex;
      // align-items: center;
      // justify-content: center;
      width: 100%;
      // position: absolute;
      // left: 0;
      // top: 1rem;
      overflow-x: auto;
      padding-bottom: 170/$base;
      position: relative;
       
      .item{
        display: flex;
        flex-direction: column;
        padding: 0 40/$base;
        justify-content: center;
        align-items: center;
        position: relative;
        &::after{
          content: "";
          height: 1/$base;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 320/$base;
          left: 85/$base;
        }
        .ord{
          font-size: .64rem;
          font-family: 'MERegular';
          color: rgba(255, 255, 255, 0.2);
          margin: 0 .37rem;
          transition: all .3s linear;
          text-align: center;
          line-height: 1.4rem;
          width: 100%;
          height: 1.4rem;
          font-weight: bolder;
          position: relative;
          &::after{
            content: "";
            width: 50/$base;
            height: 50/$base;
            background-color: rgb(255, 255, 255);
            position: absolute;
            left: 50%;
            transform: translateY(-50%);
            top: 320/$base;
            box-sizing: content-box;
            border-radius: 200px;
            border: 20/$base solid #4C4C4C;
          }
        }
        .btn {
          background-color: #f0dc2a00;
          // border-color: #F0DB2A;
          color: #000;
          // padding: 0.2rem 0.4rem;
          width: max-content;
          color: rgb(0, 0, 0);
          font-family: "MERegular";
          font-size: 0.2rem;
          border: none;
          margin: auto;
          // margin-bottom: .5rem;
          margin-top: .5rem;
          -webkit-clip-path: polygon(100% 0, 100% 80%, 90% 100%, 0 100%, 0 0);
          clip-path: polygon(100% 0, 100%  80%, 90%  100%, 0 100%, 0 0);
          font-weight: bolder;
          transition: all 0.5s linear;
          opacity: 0.6;
          &.active{
            opacity: 1;
          }
          img{
            height: 52/$mobileBase;
            // width: 272/$base;
            width: auto;
            display: block;
          }
          // &:disabled{
          //   opacity: 0.6;
          // }
        }
        &:nth-child(2){
          position: relative;
          .tip{
            position: absolute;
            bottom: -160/$base;
            left: 50%;
            width: 140/$base;
            height: 140/$base;
            // opacity: 0;
            transform: translateX(-50%) rotate(90deg);
            transition: all linear 0.5s;
          }
          // &.active{
          //   .tip{
          //     opacity: 1;
          //   }
          // }

        }
      }
    
      
    }
    // .swiperOrd{
    //   margin-left: .5rem;
    //   // width: 100vw;
    //   .swiper-wrapper{
    //     align-items: center;
    //   }
    //   text-align: center;
    //   .swiperslide{
    //     font-family: 'MERegular';
    //     font-size: .32rem;
    //     color: rgba(255, 255, 255, 0.2);
    //     // width: 1.2rem;
    //     &.swiper-slide-thumb-active{
    //       color: #fff;
    //       font-size: .48rem;
    //     }
    //   }
    // }
    .title_box{
      // padding-top: .38rem;
      position: relative;
      margin-bottom: .4rem;
      // &::after{
      //   content: '';
      //   width: 1.32rem;
      //   height: 66px;
      //   display: block;
      //   background: url('../../../assets/images/part2_adorn.png') no-repeat center;
      //   background-size: 100% 100%;
      //   position: absolute;
      //   // right: .2rem;
      //   // top: 0;
      //   right: 0.2rem;
      //   top: 0.4rem;
      //   // transform: rotateX(194deg);
      //   transform: rotate(234deg);
      // }
      
      .des{
        font-size: .32rem;
        padding: 0 .8rem;
        text-align: left;
      }
      .title{
        font-size: .35rem;
        line-height: 2;
        font-weight: 900;
        font-style: normal;
        font-family: 'Hellix';
        text-align: center;
        margin: 0;
        position: relative;
      }
      .arrow{
        width: 1.43rem;
        height: .33rem;
        margin: auto;
        img{
          height: 100%;
        }
      }
    }
    .swiperBox{
      .bg{
        width: 100%;
        height: 100%;
        position: absolute;
        max-width: 6.02rem;
        top: 0;
        left: 50%;
        background-image: url(../../../assets/images/mobild_video_bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        z-index: 2;
        pointer-events: none;
        cursor: auto;
        transform: translateX(-50%);
        margin-left: -.5rem;
      }
      margin-top: .8rem;
        width: 100%;
        height: 8rem;
        position: relative;
      .video_box{
        width: 3.1rem;
        height: 6.9rem;
        position: absolute;
        right: 50%;
        top: .6%;
        transform: translate(50%);
        overflow: hidden; 
        margin-right: -.095rem;    
        background-color: #000;  
        border-radius: .4rem; 
      }
      .content_swiper{
        height: 100%;
        .swiper-wrapper{
          height: 100%;
          .swiperslide{
            height: 100%;
            .video_con{
              width: 100%;
              height: 100%;
              margin: auto;
             
              video{
                height: 100%;
                width: 100%;
                object-fit: fill;
                // border: .07rem solid #000;
                border-radius: .4rem;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
    .swiper-footer{
      margin-top: .7rem;
      margin-bottom: .27rem;
      padding: 0 .24rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div{
        height: .25rem;
        img{
          height: 100%;
        }
      }
    }
  }
  .mobileLead{
    background-color: #ebebeb;
    padding: .16rem .23rem;
    .lead_logo{
      height: .5rem;
      margin-bottom: .2rem;
      img{
        height: 100%;
      }
    }
    .dec{
      font-family: 'Hellix';
        font-weight: normal;
        font-style: normal;
        font-size: .32rem;
        color: #1d1d1d;
        margin-top: .08rem;
        margin-bottom: .24rem;
    }
    .list{
      display: flex;
      align-items: stretch;
      // justify-content: space-between;
      position: relative;
      margin: 0 -.02rem;
      &::after{
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        width: .77rem;
        height: .77rem;
        background: url(../../../assets/images/lead_tip.png) no-repeat center;
        background-size: cover;
        transform: translateY(50%);
      }
      .item{
        width: 2.2rem;
        margin: 0 0.08rem;
        background-color: #F0DB2A;
        padding: .1rem;
        clip-path: polygon(100% 0, 100% 90%, 92% 100%, 0 100%, 0 0);
        .title{
          font-family: 'MERegular';
          font-size: .14rem;
          line-height: 3.2;
          color: #000;
        }
      
        ul{
          li{
            display: flex;
            p{
              color: #fff;
              // display: inline-block;
              font-size: .16rem;
              line-height: 1;
              padding: .1rem .1rem;
              margin-bottom: .1rem;
              background-color: #48420D;
              clip-path: polygon(100% 0, 100% 84%, 92% 100%, 0 100%, 0 0);
            }
          }
        }
     
        
      }
    }
    .pic{
      width: 100%;
      margin-top: 1.2rem;
      .selectList{
        z-index: 9;
        width: 100%;
        display: grid;
          grid-template-columns: auto auto auto auto;
        align-items: center;
        justify-content: center;
        position: relative;
        .item{
          // width: .1rem;
          // height: .8rem;
          box-sizing: border-box;
          // margin: 0 .rem;
          padding: .11rem;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: .8rem;
          }
          &.active{
            background: url(../../../assets//images/active_bg.webp) no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
      .selectPic{
        position: relative;
        height: 1800/$base;
        .item{
          position: absolute;
          bottom: 0;
          left: 130%;
          width: 100%;
          opacity: 0;
          transition: all linear 0.5s;
          background-color: #eeeeee;
          &.active{
            left: 0;
            opacity: 1;
          }
        }
      }
    }
  }
  .mobileRoadmap{
    position: relative;
    width: 100%;
    padding-top: .8rem;
    padding-bottom: 1.6rem;
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      >.title{
        font-family: 'Hellix';
        font-weight: 900;
        font-style: normal;
        font-size: .28rem;
        line-height: 1;
        margin: 0;
        // margin-top: 1.15rem;
        margin-bottom: 1.07rem;
      }
      .timeline{
        display: flex;
        width: 100%;
        margin: auto;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: -0.3rem;
        .item{
          width: 2.8rem;
          display: flex;
          align-items: flex-start;
          margin-bottom: .24rem;
          // padding: .3rem;
          .number{
            font-family: 'MERegular';
            color: #0000;
            -webkit-text-stroke: 1px #F0DB2A;  
            -webkit-text-fill-color : transparent;     
            font-size: .45rem;
            line-height: 1;
          }
          .text{
            padding-left: .14rem;
            .title{
              font-family: 'MERegular';
              font-size: .12rem;
              color: #F0DB2A;
              margin-bottom: .07rem;
            }
            .des{
              .list{
                font-size: .1rem;
                line-height: 1.2;
                margin-left: .2rem;
                li{
                  list-style: disc;
                }
              }
            }
          }
        }
      }
    }
    .dogBox{
      width: .73rem;
      position: absolute;
      left: 0;
      bottom: .3rem;
      img{
        width: 100%;
      }
    }
    .poopBox{
      width: .33rem;
      position: absolute;
      right: 0;
      top: .5rem;
      img{
        width: 100%;
      }
    }
  }
  .socialGroup{
    position: fixed;
    bottom: 0;
    height: 1.6rem;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #000;
    z-index: 9999;
    .item{
      width: .6rem;
      height: .6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      a{
        display: block;
        height: 100%;
        line-height: 1;
      }
      img{
        width: 100%;
      }
    }
  }
  .footer{
    font-size: .24rem;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: .3rem;
    background-color: #ebebeb;
    text-align: center;
    // position: absolute;
    width: 100%;
    // left: 0;
    // bottom: 50/$base;
  }
}

.mobile_loading_box{
  position: fixed;
  
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all linear .5s;
  &.hide{
    opacity: 0;
    z-index: -9999;
  }
  &.show{
    z-index: 9999999;
    opacity: 1;
  }
}