@import "../../../style/index.scss";


.puppy{
  // height: 100vh;
  width: 100%;
  box-sizing: content-box;
  .scroll-sequence {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;
  }
  .container{
    position: relative;
    width: 100%;
    height: 100%;
    .black_bg{
      width: 6.23rem;
      height: 8.38rem;
      position: absolute;
      left: 60%;
      top: 50%;
      transform: translate(-50%,-50%);
      img{
        display: block;
        -webkit-user-select: none;
        margin: auto;
        cursor: zoom-in;
        background-color: hsl(0deg 96% 66% / 0%);
        transition: background-color 300ms;
        cursor: auto;
      }
    }
    .videoBox{
      // 756 817
      width: 800/ $base;
      height: 865 / $base;
      position: absolute;
      bottom: 0;
      left: 100/$base;
      .video_con{
        width: 354 / $base;
        position: absolute;
        right: 11.5%;
        top: 0.5%;
        overflow: hidden;
        border-radius: .35rem;
        video{
          width: 100%;
        }
      }
      .bg{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../../../assets//images/video_bg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        z-index: 2;
        pointer-events: none;
        cursor: auto;
      }
    }
    .top_open{
      position: absolute;
      left: .8rem;
      top: .3rem;
      width: .32rem;
      height: .32rem;
      img{
        width: 100%;
      }
    }
    .order{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      position: absolute;
      left: 0;
      top: 1rem;
      .ord{
        font-size: .32rem;
        font-family: 'MERegular';
        color: rgba(255, 255, 255, 0.2);
        margin: 0 .185rem;
        transition: all .3s linear;
        text-align: center;
        line-height: .73rem;
        width: .86rem;
        height: .73rem;
        font-weight: bolder;
        &.active,&:hover{
          color: #fff;
          font-size: .48rem;
        }
      }
      
    }
    .textBox{
      position: absolute;
      width: 6.5rem;
      height: 840 / $base;
      min-height: 60%;
      right: 2rem;
      bottom: 0%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      padding-left: 30/$base;
      .text{
        position: relative;
        height: 30%;

        .item{
          position: absolute;
          width: 100%;
          left: 100%;
          top: 0;
          opacity: 0;
          transition: all 0.5s linear;
          &.active{
            left: 0;
            opacity: 1;
            .des{
              opacity: 1;
            }
          }
  
          .des{
            opacity: 0;
            font-size: .26rem;
            transition: all 0.5s linear;
          }
          // .title{
          //   font-size: .56rem;
          //   font-family: 'Hellix';
          //   font-weight: 900;
          //   font-style: normal;
          //   position: relative;
          //   &::after{
          //     content: '';
          //     width: 3rem;
          //     height: 1.5rem;
          //     position: absolute;
          //     right: 0;
          //     bottom: 0;
          //     transform: rotate(4deg) translateX(60%);
          //     background: url(../../../assets//images/part2_adorn.png) no-repeat center;
          //     background-size: 100% 100%;
          //   }
          // }
       
  
        }
        
      }
      .btn_group{
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        &::after{
          content: "";
          width: 1/$base;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
          position: absolute;
          top: 10/$base;
          left: 70/$base;
        }
        .item{
          display: flex;
          align-items: flex-start;
          .ord{
            font-family: MERegular;
            font-size: 28/$base;
            width: 57/$base;
            padding-top: 4/$base;
            margin-right: .32rem;
            color: rgba(255, 255, 255, 0.5);
            position: relative;
            font-weight: bolder;
            &::after{
              content: "";
              width: 6/$base;
              height: 6/$base;
              background-color: rgb(255, 255, 255);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: -19/$base;
              box-sizing: content-box;
              border-radius: 200px;
              border: 3/$base solid #4C4C4C;
            }
          }
        
          .btn{
            background: #f0dc2a00;
            // padding:  15/$base 10/$base 15/$base   18/$base;
            color: rgb(0, 0, 0);
            font-family: 'MERegular';
            font-size: 13/$base;
            border: none;
            cursor: pointer;
            // margin-top: .5rem;
            margin-bottom: 45/$base;
            // clip-path: polygon(100% 0, 100% 64%, 85% 100%, 0 100%, 0 0);
            // opacity: 0;
            transition: all 0.5s linear;
            font-weight: bolder;
            opacity: .6;
            &.active{
              opacity: 1;
            }
            img{
              height: 48/$base;
              // width: 272/$base;
              width: auto;
              display: block;
            }
            // &:disabled{
            //   opacity: 0.6;
            // }
            // &:hover{
            //   background-color: #F0DB2A;
            //   border-color: #F0DB2A;
            //   color: #000;
            // }
          }
          &:nth-child(2){
            position: relative;
             .tip{
              width: 46/$base;
              height: 46/$base;
              // opacity: 0;
              transition: all linear 0.5s;
              margin-left: 20/$base;
            }
            // &.active{
            //   .tip{
            //     opacity: 1;
            //   }
            // }

          }
        }
      }
     .next{
        // margin-top: 1.23rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80%;
      }
      
    }
    .arrow{
      width: 1.47rem;
      height: .6rem;
      position: absolute;
      left: .6rem;
      bottom: .65rem;
      img{
        width: 100%;
      }
    }
    .star{
      width: 1.49rem;
      height: .43rem;
      position: absolute;
      right: .6rem;
      bottom: .45rem;
      img{
        width: 100%;
      }
    }
   
  }
}


// .swiper-container{
//   // height: 100vh;
//   .swiperslide{
//     display: flex;
//     align-items: center;
//     .contain{
//       display: flex;
//       align-items: stretch;
//       justify-content: center;
//       padding: 0 100 / $base;
//       height: 100%;
//       width: 100%;
//      .left{
//        display: flex;
//        align-items: flex-end;
//        justify-content: flex-end;
//        width: 50%;
//       .videoBox{
//         width: 7.56rem;
//         height: 8.5rem;
//         max-width: 756px;
//         max-height: 850px;
//         // background-image: url(../../../assets//images/video_bg.png);
//         // background-repeat: no-repeat;
//         // background-size: 100% 100%;
//         // background-position: center center;
//         position: relative;
//         cursor: auto;
//         .bg{
//           width: 100%;
//           height: 100%;
//           position: absolute;
//           top: 0;
//           left: 0;
//           background-image: url(../../../assets//images/video_bg.png);
//           background-repeat: no-repeat;
//           background-size: 100% 100%;
//           background-position: center center;
//         }
//         .video_con{
//           width: 3.4rem;
//           height: 7.12rem;
//           max-width: 340px;
//           max-height: 712px;
//           position: absolute;
//           right: .83rem;
//           top: .1%;
//           overflow: hidden;
//           border-radius: .2rem;
//           video{
//             width: 100%;
//             // height: 100%;
//           }
//         }
//       }
//      }
//      .right{
//       flex: 1;
//       display: flex;
//       align-items: flex-start;
//       justify-content: center;
//       flex-direction: column;
//       padding-left: 1rem;
//       .title{
//         font-size: .56rem;
//         font-family: 'Hellix';
//         font-weight: 900;
//         font-style: normal;
//       }
//       .btn{
//         background: #F0DB2A;
//         width: 2.2rem;
//         height: .68rem;
//         color: #000;
//         font-family: 'MERegular';
//         font-size: .24rem;
//         border: none;
//         margin-top: .5rem;
//         clip-path: polygon(100% 0, 100% 64%, 85% 100%, 0 100%, 0 0);
//       }
//      }
//     }
//   }
// }


