* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: inherit;
  }
}

a {
  text-decoration: none;
  outline: none;
  color: inherit;
  cursor: pointer;

  img {
    vertical-align: top;
  }
}

img {
  border: none;
}

ul,
li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family:POPB;
  src: url(../../assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
  font-family:POP;
  src: url(../../assets/fonts/Poppins/Poppins-Regular.ttf);
}


.text-gold{
  color: #F0DB2A;
}

.font-POP{
  font-family: POP;
}

.font-POPB{
  font-family: POPB;
}

.text-center{
  text-align: center;
}