@import "../../style/index.scss";


.LitePaper{
  &.wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .dog{
      width: 1042 / $base;
      height: 1042 / $base;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 50/ $base;
    }
  }
}