html, body {
  width: 100%;
  height: 100%;
}

body{
  font-size: 14px;
  line-height: 20px;
  overflow-x: hidden;
  position: relative;
}


#root {
  width: 100%;
  background-color: #161616;
  color: white;
  -webkit-user-select: none;
  user-select: none;
}