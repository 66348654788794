@import "../../../style/index.scss";

.banner{
  // min-height: 100vh;
  // padding: 0 100 / $base;
  height: 100%;
  // padding-top: 2.3rem;
  // padding-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  // padding-top: .8rem;
  .container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .bannerLogo{
      width: 720 / $base;
      position: relative;
      // margin-top: -2.8rem;
      margin-top: -140 / $base;
    }
    .des{
      // text-shadow: 0 0 0.8em rgba(0, 0, 0, 0.3), 0 0 0.8em rgba(0, 0, 0, 0.3);
    
      color:#fff;
      position: relative;
      text-align: center;
      font-size: .26rem;
      max-width: 10rem;
      font-family: POP;
      // -webkit-text-stroke-width: .04rem;
      // -webkit-text-stroke-color: rgba(0, 0, 0, 0.2);
      span{
      //   color: #F0DB2A;
      font-family: POPB;
      }
      
      .stroke{
        -webkit-text-stroke: 10/$base rgba(0, 0, 0, 0.2);
        // &::before{
        //   content: attr(text);
        //   position: absolute;
        //   -webkit-text-stroke: 10px cadetblue;
        //   -webkit-mask: linear-gradient(to bottom,transparent,black); 
        // }
      }
      .text {
        color: rgb(255, 255, 255);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
        
        span{
          color: #F0DB2A;

        }
        &::before{
          content: attr(text);
          position: absolute;
          color: rgb(255, 255, 255);
          // -webkit-mask: linear-gradient(to top,transparent,black);
        }
      }
      
      
    }
  }
  .bg{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    height: auto;
    min-width: 1920px;
    width: 100vw;
    max-height: 100vh;
    overflow: hidden;
    //background: url(https://d2wg65jwnvf9nj.cloudfront.net/poopoopuppy/banner_small.jpg) no-repeat center;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
  }

  .mask{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    z-index: 99;
    opacity: 0;
    transition: all linear .3s;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.12);
    .content{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 120/$base;

      .toast{
        margin-bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        font-family: MERegular;
        text-align: center;
        font-size: 28/$base;
        max-width: 420/$base;
        border-radius: 4px;
        padding: 20/$base 40/$base;
        box-sizing: content-box;
        margin: auto;
      }
    }
    &.active{
      opacity: 1;
    }
  }
 
    .btn_group{
      display: flex;
      align-items: center;
      .btn{
        display: flex;
        width: 2.2rem;
        align-items: center;
        justify-content: center;
        font-size: .2rem;
        line-height: 1;
        color: #fff;
        font-family: 'MERegular';
        transition: all linear .3s;
        outline: none;
        border: none;
        cursor: pointer;
        .iconBox{
          width: .30rem;
          margin-right: .13rem;
          svg{
            path{
              fill: #fff;
              transition: all linear .3s;
            }
          }
        }
        margin-top: 0.5rem;
        -webkit-clip-path: polygon(100% 0, 100% 64%, 85% 100%, 0 100%, 0 0);
        clip-path: polygon(100% 0, 100% 64%, 85% 100%, 0 100%, 0 0);
        background-color: rgba(0, 0, 0, 0.27);
        -webkit-backdrop-filter: blur(10 / $base);
	      backdrop-filter: blur(10 / $base);
        
        height: .68rem;
        position: relative;
     
        &:first-child{
          margin-right: .24rem;
          background-color: #F0DB2A;
          border-color: #F0DB2A;
          color: #000;
          .iconBox{
            svg{
              path{
                fill: rgb(0, 0, 0);
                transition: all linear .3s;
              }
            }
          }
        }
        // &:hover{
        //   background-color: #F0DB2A;
        //   border-color: #F0DB2A;
        //   .iconBox{
        //     svg{
        //       path{
        //         fill: rgb(0, 0, 0);
        //       }
        //     }
        //   }
        //   color: #000;
        //   &::after{
        //     background-color: #F0DB2A;
        //   }
        // }
      }       
    }
  // }
  // .right{
  //   .banner1{
  //     width: 9.53rem;
  //   }
  // }
}